<template>
  <div class="row p-0">
    <div class="col-md-12 p-0">
      <a-skeleton active v-if="loading" />
      <div v-else>
        <h4>{{ $t("etablissement.designation") + ": " + data.name }}</h4>
        <a-tag color="cyan" class="mb-2">
          {{
            feesData.mode == "M" ? "Paiment mensuelle" : "Paiement par tranche"
          }}
        </a-tag>
        <a-table
          :pagination="true"
          :data-source="feesData"
          :columns="columns"
          rowKey="_id"
        >
          <template slot="isPayed" slot-scope="text">
            <a-tag :color="text ? 'green' : 'red'">
              <b v-if="text">{{ $t("profil.payes") }}</b>
              <b v-else> {{ $t("profil.nonPayes") }}</b>
            </a-tag>
          </template>
          <span slot="feeName" slot-scope="text">
            <p v-if="text == 'inscription'">
              {{ text }}
            </p>
            <p v-else>
              {{ formatFeeName(text) }}
            </p>
          </span>
          <template slot="operation" slot-scope="text, record">
            <a-button
              type="primary"
              :disabled="status != 'active'"
              @click="ClassRoomModal(record)"
            >
              <a-icon type="eye" /> {{ $t("profil.paiement") }}
            </a-button>
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import { mapState } from "vuex";
import apiClient from "@/services/axios";
import Vue from "vue";
import VueRouter from "vue-router";
import moment from "moment";
Vue.use(VueRouter);
import { formatFeeName } from "@/utilities/settings";

export default {
  props: {
    studentId: {
      required: true,
    },
  },
  computed: {
    ...mapState(["settings", "user"]),
  },
  components: {},
  async created() {
    this.$gtag.pageview({
      page_path: this.$route.path,
      page_location: window.location.href,
      page_title: document.title + " - paiement scolartié",
    });
  },
  mounted() {
    const building = this.user.building.find(
      (el) => el.dbName == this.settings.activeBuilding
    );
    if (building) this.reductionType = building.reductionType || "pourcentage";

    /* if (this.reductionType == "dinar")
      this.columns[1].title = "Réduction (TND)";
    else this.columns[1].title = "Réduction (%)";*/

    this.fetchPayments();
  },

  data() {
    return {
      editingReduction: null,
      feesData: [],
      data: [],
      loading: true,
      loadingSave: false,
      moment,
      reductionType: "dinar",
      columns: [
        {
          title: this.$t("paiement.mois"),
          dataIndex: "feeName",
          key: "feeName",
          scopedSlots: {
            customRender: "feeName",
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: this.$t("profil.etat"),
          dataIndex: "isPayed",
          key: "isPayed",
          scopedSlots: {
            customRender: "isPayed",
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          scopedSlots: { customRender: "isPayed" },

          sorter: (a, b) => a.status.length - b.status.length,
          sortDirections: ["descend", "ascend"],
        },
      ],
    };
  },
  methods: {
    formatFeeName,
    async fetchPayments() {
      this.visibleModal = true;
      this.loading = true;
      await apiClient
        .get("/payment/pvalidator/" + this.studentId)
        .then((res) => {
          this.data = res.data.filter((p) => p.typeAS == "S")[0];
          this.feesData = this.data.fees || [];
        })
        .catch((err) => {
          console.error(err);
          this.feesData = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
