<template>
  <div>
    <a-button
      :class="profile ? '' : 'm-2'"
      :loading="loadingPaymentStatus"
      :disabled="loadingPaymentStatus"
      @click="openPaymentStatusModal"
      :type="profile ? 'primary' : ''"
      >{{ $t("paymentStatus.paymentStatus") }}</a-button
    >

    <a-modal
      :dialog-style="{ top: '10px' }"
      :title="$t('paymentStatus.paymentStatus')"
      :visible="visiblePaymentStatus"
      width="65%"
      @cancel="() => (visiblePaymentStatus = false)"
      @ok="() => (visiblePaymentStatus = false)"
    >
      <div style="width: 100%">
        <a-label>{{ $t("menu.listeEleves") }} : </a-label>
        <a-select
          :allowClear="false"
          mode="multiple"
          :placeholder="$t('menu.listeEleves')"
          style="width: 60%"
          option-filter-prop="children"
          class="ml-3 mb-3"
          v-model="selectedStudents"
        >
          <a-select-option
            v-for="student in paymentStatus"
            :key="student._id"
            :value="student._id"
          >
            {{ student.fullName }}
          </a-select-option>
        </a-select>
        <a-button
          type="primary"
          class="ml-5 mr-5"
          :loading="loadingPaymentStatusPdf"
          :disabled="loadingPaymentStatusPdf"
          @click="printPaymentStatus"
          >{{ $t("action.imprimer") }}</a-button
        >

        <a-divider>
          <div style="margin-bottom: 16px">
            <span style="margin-left: 8px">
              {{ $t("paymentStatus.paymentStatus") }}
            </span>
          </div>
        </a-divider>

        <!-- <a-divider class="font-size-13 mt-0">{{
          $t("paymentStatus.paidAmount")
        }}</a-divider> -->
        <h5>{{ $t("paymentStatus.paidAmount") }}</h5>
        <div v-if="paidStatus.length > 0">
          <a-collapse
            v-for="p in paidStatus"
            :key="p.name"
            default-active-key="0"
            :bordered="false"
            class="mt-1"
          >
            <template #expandIcon="props">
              <span class="custom-tag" style="font-size: 15px"
                ><a-icon
                  type="caret-right"
                  :rotate="props.isActive ? 90 : 0"
                /><a-tag large="large" color="cyan">{{ p.name }}</a-tag
                >: {{ p.money }} TND
                <a-tag color="green" v-if="p.completed">{{
                  $t("profil.payes")
                }}</a-tag></span
              >
            </template>
            <a-collapse-panel
              key="1"
              style="background: #ffffff; overflow: hidden; border: 0"
            >
              <div v-for="student in p.students" :key="student.fullName">
                <p class="pl-4 mt-2" style="font-size: 18">
                  <b>{{ student.fullName }} :</b>
                </p>
                <p
                  class="pl-5 mt-2"
                  v-for="d in student.details"
                  :key="d.feeName"
                >
                  <b
                    ><span>{{ formatFeeName(d.feeName) }}</span></b
                  >
                  : {{ d.prix }} TND
                  <a-tag color="green">{{ $t("profil.payes") }}</a-tag>
                </p>
                <div style="width: 300px">
                  <a-divider class="p-0 m-0"></a-divider>
                </div>
              </div>
            </a-collapse-panel>
          </a-collapse>
        </div>
        <div v-else class="text-center">
          <a-tag>{{ $t("paymentStatus.nothingPaid") }}</a-tag>
        </div>

        <!-- <a-divider class="font-size-13">{{
          $t("paymentStatus.monthRemainingPayments", {
            month: currentMonthName,
          })
        }}</a-divider> -->
        <!-- <a-divider></a-divider> -->
        <h5 class="mt-3">
          {{
            $t("paymentStatus.monthRemainingPayments", {
              month: currentMonthName,
            })
          }}
        </h5>
        <div v-if="currentMonthPay.length > 0">
          <a-collapse
            v-for="p in currentMonthPay"
            :key="p.name"
            default-active-key="0"
            :bordered="false"
            class="mt-1"
          >
            <template #expandIcon="props">
              <span class="custom-tag" style="font-size: 15px"
                ><a-icon
                  type="caret-right"
                  :rotate="props.isActive ? 90 : 0"
                /><a-tag large="large" color="orange">{{ p.name }}</a-tag
                >: {{ p.money }} TND</span
              >
            </template>
            <a-collapse-panel
              key="1"
              style="background: #ffffff; overflow: hidden; border: 0"
            >
              <div v-for="student in p.students" :key="student.fullName">
                <p class="pl-4 mt-2" style="font-size: 18">
                  <b>{{ student.fullName }} :</b>
                </p>
                <p
                  class="pl-5 mt-2"
                  v-for="d in student.details"
                  :key="d.feeName"
                >
                  <b
                    ><span>{{ formatFeeName(d.feeName) }}</span></b
                  >
                  : {{ d.prix }} TND
                  <a-tag color="red">{{ $t("profil.nonPayes") }}</a-tag>
                </p>
                <div style="width: 300px">
                  <a-divider class="p-0 m-0"></a-divider>
                </div>
              </div>
            </a-collapse-panel>
          </a-collapse>
        </div>
        <div v-else class="text-center">
          <a-tag>{{ $t("paymentStatus.noRemainingPyaments") }}</a-tag>
        </div>

        <!-- <a-divider class="font-size-13">{{
          $t("paymentStatus.allRemainingPayments")
        }}</a-divider> -->
        <!-- <a-divider></a-divider> -->
        <h5 class="mt-3">{{ $t("paymentStatus.allRemainingPayments") }}</h5>
        <div v-if="resetToPay.length > 0">
          <a-collapse
            v-for="p in resetToPay"
            :key="p.name"
            default-active-key="0"
            :bordered="false"
            class="mt-1"
          >
            <template #expandIcon="props">
              <span class="custom-tag" style="font-size: 15px"
                ><a-icon
                  type="caret-right"
                  :rotate="props.isActive ? 90 : 0"
                /><a-tag large="large" color="orange">{{ p.name }}</a-tag
                >: {{ p.money }} TND</span
              >
            </template>
            <a-collapse-panel
              key="1"
              style="background: #ffffff; overflow: hidden; border: 0"
            >
              <div v-for="student in p.students" :key="student.fullName">
                <p class="pl-4 mt-3" style="font-size: 18">
                  <b>{{ student.fullName }} :</b>
                </p>
                <p
                  class="pl-5 mt-2"
                  v-for="d in student.details"
                  :key="d.label"
                >
                  <b
                    ><span>{{ formatFeeName(d.feeName) }}</span></b
                  >
                  : {{ d.prix }} TND
                  <a-tag color="red">{{ $t("profil.nonPayes") }}</a-tag>
                </p>
                <div style="width: 300px">
                  <a-divider class="p-0 m-0"></a-divider>
                </div>
              </div>
            </a-collapse-panel>
          </a-collapse>
        </div>
        <div v-else class="text-center">
          <a-tag>{{ $t("paymentStatus.noRemainingPyaments") }}</a-tag>
        </div>

        <a-divider>
          <div style="margin-bottom: 16px">
            <span style="margin-left: 8px"> Historique des avances </span>
          </div>
        </a-divider>
        <div class="text-center" width="100%">
          <div style="margin-bottom: 16px">
            <a-badge color="green" dot>
              <a-icon type="notification" />
            </a-badge>

            <span class="h5 font-weight-bold text-success pl-2">
              Solde parent :
              {{ dataAvance[0] ? dataAvance[0].parent.solde : 0 }}
            </span>
          </div>
          <a-table
            :columns="columnsAvance"
            :data-source="dataAvance"
            size="middle"
          >
            <template slot="date" slot-scope="text">
              {{ moment(text).format("DD/MM/YYYY") }}
            </template>
          </a-table>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
/* eslint-disable */

import { mapState, mapActions } from "vuex";
import apiClient from "@/services/axios";
import moment from "moment";
import JsPDF from "jspdf";
import "jspdf-autotable";
import { formatFeeName } from "@/utilities/settings";

export default {
  props: ["currentParent", "profile", "selectedStudent"],

  data: function () {
    return {
      visiblePaymentStatus: false,
      loadingPaymentStatus: false,
      loadingPaymentStatusPdf: false,
      selectedStudents: [],
      dataAvance: [],
      moment,
      paymentStatus: [],
      currentMonthName: moment().format("MMMM"),
      columnsAvance: [
        {
          title: "Date d'ajout",
          dataIndex: "createdAt",
          key: "date",
          scopedSlots: {
            customRender: "date",
          },
        },
        {
          title: "Montant espéce",
          dataIndex: "montantEspece",
          key: "montantEspece",
          sorter: (a, b) => a.montantEspece - b.montantEspece,
        },
        {
          title: "Montant chèque",
          dataIndex: "montantCheque",
          key: "montantCheque",
          sorter: (a, b) => a.montantCheque - b.montantCheque,
        },
        {
          title: "Montant avance",
          dataIndex: "totalAvance",
          key: "totalAvance",
          sorter: (a, b) => a.totalAvance - b.totalAvance,
        },
      ],
    };
  },
  computed: {
    ...mapState(["user", "settings"]),
    paidStatus() {
      let paidStatus = [];
      this.selectedStudents.forEach((studentID) => {
        const student = this.paymentStatus.find((s) => s._id == studentID);
        if (student) {
          student.studentStatus.paid.map((p) => {
            const payement = paidStatus.find((ps) => ps.name == p.name);
            if (!payement)
              paidStatus.push({
                money: Number(p.money),
                name: p.name,
                students: [
                  {
                    completed: p.completed,
                    details: p.details,
                    fullName: student.fullName,
                  },
                ],
              });
            else {
              payement.money += Number(p.money);
              payement.students.push({
                details: p.details,
                fullName: student.fullName,
              });
            }
          });
        }

        paidStatus.map((p) => {
          p.completed = p.students.every((s) => s.completed);
          p.money.toFixed(2);
        });
      });

      return paidStatus;
    },
    currentMonthPay() {
      let currentMonthPay = [];
      this.selectedStudents.forEach((studentID) => {
        const student = this.paymentStatus.find((s) => s._id == studentID);
        if (student) {
          student.studentStatus.currentMonthPay.map((p) => {
            const payment = currentMonthPay.find((ps) => ps.name == p.name);
            if (!payment) {
              currentMonthPay.push({
                money: Number(p.money),
                name: p.name,
                students: [
                  {
                    details: p.details,
                    fullName: student.fullName,
                  },
                ],
              });
            } else {
              payment.money += Number(p.money);
              payment.students.push({
                details: p.details,
                fullName: student.fullName,
              });
            }
          });
        }
        currentMonthPay.map((p) => {
          p.money.toFixed(2);
        });
      });

      return currentMonthPay;
    },
    resetToPay() {
      let resetToPay = [];
      this.selectedStudents.forEach((studentID) => {
        const student = this.paymentStatus.find((s) => s._id == studentID);
        if (student) {
          student.studentStatus.resetToPay.map((p) => {
            const payment = resetToPay.find((ps) => ps.name == p.name);
            if (!payment) {
              resetToPay.push({
                money: Number(p.money),
                name: p.name,
                students: [
                  {
                    details: p.details,
                    fullName: student.fullName,
                  },
                ],
              });
            } else {
              payment.money += Number(p.money);
              payment.students.push({
                details: p.details,
                fullName: student.fullName,
              });
            }
          });
        }
        resetToPay.map((p) => {
          p.money.toFixed(2);
        });
      });

      return resetToPay;
    },
  },
  methods: {
    formatFeeName,
    async openPaymentStatusModal() {
      this.loadingPaymentStatus = true;
      await apiClient
        .get("/paymentStatus/parent/" + this.currentParent)
        .then((res) => {
          this.paymentStatus = res.data;
          if (!this.selectedStudent)
            this.selectedStudents = res.data.map((e) => e._id);
          else this.selectedStudents = [this.selectedStudent];
        });

      await apiClient
        .get("/avance/" + this.currentParent)
        .then((res) => {
          this.dataAvance = res.data.map((e) => {
            return {
              key: e._id,
              ...e,
            };
          });
        })
        .catch((e) => {
          console.error(e.stack);
          this.$message.warning(
            "Un problème du connexion, veuillez rafraîchir la page!4"
          );
        });
      this.loadingPaymentStatus = false;
      this.visiblePaymentStatus = true;
    },
    printPaymentStatus() {
      this.$gtag.event("Imp PaymentStatus", {
        event_category: "Impression PDF",
        event_label: "Profile eleve section",
        value: 1,
      });

      this.loadingPaymentStatusPdf = true;
      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }

      let data = this.paymentStatus.filter((s) =>
        this.selectedStudents.includes(s._id)
      );

      var doc = new JsPDF();
      doc.setFont("Amiri");
      doc.setFontSize(15);
      var today = new Date();
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };

      doc.setFontSize(10);

      doc.text(
        130,
        20,
        "Imprimé le : " + " " + today.toLocaleDateString("fr-FR", options)
      );
      doc.setFontSize(15);

      const studentList = [];

      doc.text(100, 50, "Statut de paiement", "center");

      data.map((s) => {
        studentList.push(s.fullName);
      });

      doc.text(100, 60, studentList.join(" - "), "center");

      doc.addImage(imgData, "JPEG", 10, 10, 40, 40);

      data = data.map((student) => {
        let totalPaid = 0;
        let totalNotPaid = 0;
        const status = [];
        student.studentStatus.resetToPay.map((p) => {
          const _data = [];
          p.details.map((detail) => {
            _data.push({
              label: formatFeeName(detail.feeName),
              prix: detail.prix,
              paid: false,
            });
          });
          totalNotPaid += Number(p.money);
          status.push({ name: p.name, data: _data });
        });

        student.studentStatus.paid.map((p) => {
          totalPaid += Number(p.money);
          const _data = [];
          p.details.map((detail) => {
            _data.push({
              label: formatFeeName(detail.feeName),
              prix: detail.prix,
              paid: true,
            });
          });
          const index = status.findIndex((s) => s.name === p.name);
          if (index !== -1) {
            status[index].data.push(..._data);
          } else {
            status.push({ name: p.name, data: _data });
          }

          status.map((s) => {
            s.data.sort((a, b) => {
              if (a.feeName === "inscription") return -1;
              if (b.feeName === "inscription") return 1;
            });
          });
        });

        return {
          fullName: student.fullName,
          status,
          totalNotPaid: totalNotPaid,
          totalPaid: totalPaid,
        };
      });

      const array = [];
      var counter = 1;

      for (let i = 0; i < data.length; i++) {
        let arr = [];

        const rowSpan = data[i].status.reduce((acc, curr) => {
          return acc + curr.data.length;
        }, 0);

        arr.push({
          rowSpan: rowSpan,
          content: counter++,
          styles: { valign: "middle" },
        });

        arr.push({
          rowSpan: rowSpan,
          content: data[i].fullName,
          styles: { valign: "middle" },
        });

        data[i].status.map((category) => {
          arr.push({
            rowSpan: category.data.length,
            content: category.name,
            styles: { valign: "middle" },
          });

          category.data.map((d) => {
            arr.push({
              content: this.formatFeeName(d.feeName),
              styles: { valign: "middle" },
            });
            arr.push({
              content: d.prix,
              styles: { valign: "middle" },
            });
            arr.push({
              content: d.paid ? "Payé" : "Impayé",
              styles: {
                textColor: d.paid ? [14, 115, 31] : [209, 23, 45],
                fontStyle: "bold",
                valign: "middle",
              },
            });

            if (category.data.indexOf(d) !== category.data.length - 1) {
              array.push(arr);
              arr = [];
            }
          });

          array.push(arr);
          arr = [];
        });
      }

      doc.autoTable({
        theme: "grid",
        rowPageBreak: "avoid",
        styles: { font: "Amiri" },
        headStyles: {
          lineWidth: 0.01,
          lineColor: [0, 0, 0, 0],
        },
        head: [
          ["Num", "Nom de l'élève", "Catégorie", "Frais", "Montant", "Etat"],
        ],
        foot: [
          [
            {
              content:
                "Total non payé : " +
                Number(
                  data.reduce((acc, curr) => acc + curr.totalNotPaid, 0)
                ).toFixed(2),
              colSpan: 3,
              styles: {
                halign: "center",
                lineWidth: 0.01,
                lineColor: [0, 0, 0, 0],
              },
            },
            {
              content:
                "Total payé : " +
                Number(
                  data.reduce((acc, curr) => acc + curr.totalPaid, 0)
                ).toFixed(2),
              colSpan: 3,
              styles: {
                halign: "center",
                lineWidth: 0.01,
                lineColor: [0, 0, 0, 0],
              },
            },
          ],
        ],
        body: array,
        margin: { top: 65 },
        didDrawPage: function (data) {
          // Reseting top margin. The change will be reflected only after print the first page.
          data.settings.margin.top = 10;
        },
      });

      let finalY = doc.lastAutoTable.finalY; // The y position on the page
      doc.setTextColor("#41b883");
      doc.setFontSize(16);

      const avanceData = [];
      this.dataAvance.map((avance) => {
        avanceData.push([
          moment(avance.date).format("DD/MM/YYYY"),
          avance.montantEspece,
          avance.montantCheque,
          avance.totalAvance,
        ]);
      });
      avanceData.push([
        {
          content:
            "Solde parent : " +
            String(this.dataAvance[0] ? this.dataAvance[0].parent.solde : 0),
          colSpan: 4,
          style: { halign: "center", valign: "center", fontSize: 18 },
        },
      ]);

      doc.addPage();

      doc.autoTable({
        theme: "grid",
        rowPageBreak: "avoid",
        styles: { font: "Amiri", valign: "middle", halign: "center" },
        headStyles: {
          lineWidth: 0.01,
          lineColor: [0, 0, 0, 0],
        },
        head: [
          [
            {
              content: "Historique des avances",
              colSpan: 4,
              styles: { halign: "center", fontSize: "16" },
            },
          ],
          [
            "Date d'ajout",
            "Montant espéce",
            "Montant chèque",
            "Montant avance",
          ],
        ],
        body: avanceData,
        didDrawPage: function (data) {
          // Reseting top margin. The change will be reflected only after print the first page.
          data.settings.margin.top = 10;
        },
      });
      //page numbering
      const pages = doc.internal.getNumberOfPages();
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      doc.setFontSize(8); //Optional

      doc.setTextColor(0, 0, 0);

      for (let j = 1; j < pages + 1; j++) {
        let horizontalPos = pageWidth / 2;
        let verticalPos = pageHeight - 10;
        doc.setPage(j);
        doc.text(`Page ${j} / ${pages}`, horizontalPos, verticalPos, {
          align: "center",
        });
      }

      this.loadingPaymentStatusPdf = false;
      //end page numbering
      doc.save("Statut de paiement.pdf");
    },
  },
};
</script>

<style scoped>
.custom-tag .ant-tag {
  padding: 4px 7px 4px 7px;
  font-size: 13px;
  margin-left: 3px;
}

.custom-tag .ant-tag:hover {
  cursor: pointer;
}
</style>
