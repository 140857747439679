<template>
  <div class="row p-0">
    <a-skeleton active v-if="loading" />
    <div class="col-md-12 p-0" v-else>
      <a-collapse v-model="activeKey" class="mb-5">
        <a-collapse-panel
          v-for="(value, key) in data"
          :key="key"
          :header="key"
          :disabled="false"
        >
          <a-table
            :columns="columns"
            :data-source="value.reductions"
            :pagination="false"
          >
            <span slot="feeName" slot-scope="text">
              <p v-if="text == 'inscription'">
                {{ text }}
              </p>
              <p v-else>
                {{ formatFeeName(text) }}
              </p>
            </span>
            <span slot="repReductionValue" slot-scope="text, record">
              <span
                v-if="
                  editingReduction &&
                  editingReduction.feeName == record.feeName &&
                  editingReduction.payment == value.payment
                "
              >
                <a-input-number v-model="editingReduction.value" />
              </span>
              <a-tag color="green" v-else>
                <span v-if="reductionType == 'dinar'">
                  {{
                    (record.feeBeforeRed - record.feeAfterReduction).toFixed(2)
                  }}
                  TND
                </span>
                <span v-else> {{ Number(text).toFixed(2) }} % </span>
              </a-tag>
            </span>
            <span slot="feeAfterReduction" slot-scope="text, record">
              <span
                v-if="
                  editingReduction &&
                  editingReduction.feeName == record.feeName &&
                  editingReduction.payment == value.payment
                "
              >
                <span v-if="reductionType == 'dinar'">
                  {{
                    (record.feeBeforeRed - editingReduction.value).toFixed(2)
                  }}
                  TND
                </span>
                <span v-else>
                  {{
                    (
                      record.feeBeforeRed -
                      (record.feeBeforeRed * editingReduction.value) / 100
                    ).toFixed(2)
                  }}
                </span>
              </span>
              <span v-else>
                {{ Number(text).toFixed(2) }}
              </span>
            </span>
            <div slot="action" slot-scope="text, record">
              <span v-if="!record.isPayed">
                <a-button
                  v-if="
                    !(
                      editingReduction &&
                      editingReduction.feeName == record.feeName &&
                      editingReduction.payment == value.payment
                    )
                  "
                  type="primary"
                  :disabled="
                    editingReduction &&
                    (editingReduction.feeName != record.feeName ||
                      editingReduction.payment != value.payment)
                  "
                  @click="
                    () => {
                      editingReduction = {
                        feeName: record.feeName,
                        payment: value.payment,
                        value:
                          reductionType == 'dinar'
                            ? record.feeBeforeRed - record.feeAfterReduction
                            : record.repReductionValue,
                        record: record,
                      };
                    }
                  "
                  class="mr-3"
                >
                  {{ $t("action.modifier") }}
                </a-button>
                <div
                  v-if="
                    editingReduction &&
                    editingReduction.feeName == record.feeName &&
                    editingReduction.payment == value.payment
                  "
                >
                  <a-button
                    @click="saveReduction"
                    :loading="loadingSave"
                    :disabled="loadingSave"
                    type="primary"
                    class="mr-3"
                  >
                    {{ $t("action.enregistrer") }}
                  </a-button>
                  <a-button
                    @click="
                      () => {
                        editingReduction = null;
                      }
                    "
                    class="mr-3"
                  >
                    {{ $t("action.annuler") }}
                  </a-button>
                </div>
              </span>
            </div>
          </a-table>
        </a-collapse-panel>
      </a-collapse>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import { mapState } from "vuex";
import apiClient from "@/services/axios";
import Vue from "vue";
import VueRouter from "vue-router";
import moment from "moment";
import { formatFeeName } from "@/utilities/settings";

const frMonths = {};
const months = moment.months();
for (let i = 0; i < months.length; i++) frMonths[i + 1] = months[i];
Vue.use(VueRouter);

const columns = [
  {
    title: "Designation",
    dataIndex: "feeName",
    key: "feeName",
    scopedSlots: {
      customRender: "feeName",
    },
  },
  {
    title: "Réduction",
    dataIndex: "repReductionValue",
    key: "repReductionValue",
    scopedSlots: {
      customRender: "repReductionValue",
    },
  },
  {
    title: "Montant avant réduction",
    dataIndex: "feeBeforeRed",
    key: "feeBeforeRed",
  },
  {
    title: "Montant aprés réduction",
    key: "feeAfterReduction",
    dataIndex: "feeAfterReduction",
    scopedSlots: {
      customRender: "feeAfterReduction",
    },
  },
  {
    title: "Actions",
    key: "action",
    scopedSlots: {
      customRender: "action",
    },
  },
];
export default {
  props: {
    studentId: {
      required: true,
    },
  },
  computed: {
    ...mapState(["settings", "user"]),
  },
  components: {},
  async created() {
    this.$gtag.pageview({
      page_path: this.$route.path,
      page_location: window.location.href,
      page_title: document.title + " - Réduction de base",
    });
  },
  mounted() {
    const building = this.user.building.find(
      (el) => el.dbName == this.settings.activeBuilding
    );
    if (building) this.reductionType = building.reductionType || "pourcentage";

    if (this.reductionType == "dinar")
      this.columns[1].title = "Réduction (TND)";
    else this.columns[1].title = "Réduction (%)";

    this.getRepRuductions();
  },

  data() {
    return {
      editingReduction: null,
      activeKey: [],
      columns,
      frMonths,
      data: [],
      loading: true,
      loadingSave: false,
      moment,
      reductionType: "dinar",
    };
  },
  methods: {
    formatFeeName,
    getRepRuductions(activeKey) {
      apiClient
        .get("/payment/reductionRep/" + this.studentId)
        .then((res) => {
          this.data = res.data;
          const keys = Object.keys(res.data);

          if (keys.length > 0) {
            this.activeKey = activeKey || [keys[0]];
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    saveReduction() {
      this.loadingSave = true;

      // convert dinar to % by using editingReduction.record.feeBeforeRed
      if (this.reductionType == "dinar")
        this.editingReduction.value =
          (this.editingReduction.value /
            this.editingReduction.record.feeBeforeRed) *
          100;

      const data = {
        value: this.editingReduction.value,
        feeName: this.editingReduction.feeName,
        payment: this.editingReduction.payment,
      };

      apiClient
        .patch("/payment/reductionRep/", data)
        .then((res) => {
          this.getRepRuductions(this.activeKey);
          this.$emit("updateActivities");
          this.$emit("updateClassrooms");
        })
        .finally(() => {
          this.loadingSave = false;
          this.editingReduction = null;
        });
    },
  },
};
</script>
